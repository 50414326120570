.form-wrapper .ant-form{
    border: 1px dotted red;
    text-align: center;
    max-width: 800px;
    padding: 25px 35px;
    margin: auto;
    border: 1px solid #30317C;
    border-radius: 5px;
    background: #0A0F1D;
}

.form-wrapper .ant-form label{
    display: none;
}

.form-wrapper .ant-form .ant-input-number,
.form-wrapper .ant-form input,
.form-wrapper .ant-form textarea{
    padding: 15px 10px;
    width: 100%;
    text-align: center;
}

.ant-input-number-handler-wrap{
    display: none;
}

/* .form-wrapper .ant-form .ant-input-number{
    width: 600px;
}

.form-wrapper .ant-form .ant-input-number input{
    padding: 25px 10px !important;
    width: 100%;
    text-align: center;
}
*/

.form-wrapper .ant-form .contact-input-wrapper{
    position: relative;
}

.form-wrapper .ant-form button[type="submit"]{
    background: #3BB900;
    border-color: #3BB900;
}

.form-inline-btn{
    width: 129px;
    height: 45px;
    position: absolute;
    top: 8px;
    right: 5px;
    
} 

.btn-icon{
    width: 16px;
    margin-left: 10px;
}