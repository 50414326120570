.event-description-card {
    /* max-width: 370px; */
    min-width: 100%;
    min-height: 500px;
    /* margin-left: 15px; */
    margin-right: 20px;
    /* margin-bottom: 10px; */
    background: #0A0F1D;
    border: 1px solid #30317C;
    /* border: 2px solid #4648D8; */
    border-radius: 10px;
    transition: all 0.25s ease-in-out;
    position: relative;
}

.comments-card {
    /* max-width: 370px; */
    min-width: 100%;
    /* margin-left: 15px; */
    margin-right: 20px;
    /* margin-bottom: 10px; */
    background: #0A0F1D;
    border: 1px solid #30317C;
    /* border: 2px solid #4648D8; */
    border-radius: 10px;
    transition: all 0.25s ease-in-out;
    position: relative;
}

.search-bar {
    /* min-width: 200px; */
    width: 250px;
}

.star-icon {
    margin-right: 8px;
    margin-top: -3.5px;
}

.sm-icon {
    border-radius: 50%;
    margin-right: 8px;
}

.horizontal-line {
    border: 1px solid #3F3D56;
}

.horizontal-line-footer {
    position: absolute;
    bottom: 30px;
}

.bottom-text {
    position: absolute;
    bottom: 5px;
    left: 15px;
}

.add-to-watchlist-info-card {
    border: 1px solid #fa8c16;
    background: #612500;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.25s ease-in-out;
}

.add-to-watchlist-info-card:hover {
    color: #ffffff;
    border: 1px solid #fa8c16;
    box-shadow: 0px 0px 15px 2px #fa8c16;
}

.add-to-watchlist-info-card::after {
    background: #fa8c16;
    border: 1px solid #fa8c16;
}

.add-to-watchlist-info-card:focus {
    color: #ffffff;
    border-color: #fa8c16;
    background: #d46b08;
}

.vote-button-info-card {
    border: 1px solid #3BB900;
    background: #3bb9002b;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.25s ease-in-out;
}

.vote-button-info-card:hover {
    color: #ffffff;
    border: 1px solid #3BB900;
    box-shadow: 0px 0px 15px 2px #3BB900;
}

.vote-button-info-card::after {
    background: #3BB900;
    border: 1px solid #3BB900;
}

.vote-button-info-card:focus {
    color: #ffffff;
    border-color: #3BB900;
    background: #3BB900;
}

.vote-button-info-card-voted {
    background: #3BB900;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
}

.vote-button-info-card-voted:hover {
    background: #3BB900;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    color: #ffffff;
}

.vote-button-info-card-voted:focus {
    background: #3BB900;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    color: #ffffff;
}

.vote-button-info-card-voted::after {
    background: #3BB900;
    border: 1px solid #3BB900;
}
.audit-report-button {
    background: #3ddaeb7a;
    border: solid 1px #3dd9eb;
}
.audit-report-button:hover {
    color: #ffffff;
    border: 1px solid #3dd9eb;
    box-shadow: 0px 0px 15px 2px #3dd9eb;
}

.audit-report-button::after {
    background: #3dd9eb;
    border: 1px solid #3dd9eb;
}

.audit-report-button:focus {
    color: #ffffff;
    border-color: #3dd9eb;
    background: #3dd9eb;
}

.added-to-favs{
    background-color: #d46b08 !important;
}

.event-description-card{
    min-height: 538px;
}