/* Home page - Cover section*/
.cover {
    background: url('../../../images/cover-bg.png');
}

.cover .ant-row {
    min-height: 100vh;

}

.cover::before {
    margin-top: 40px;
    content: '';
    position: absolute;
    left: -12%;
    top: 0;
    height: 800px;
    width: 800px;
    background: url('../../../images/blurred-bg.png');
    background-size: 110%;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 2;
    opacity: 0.5;
}

.cover .heading-1 {
    max-width: 420px;
}

.cover .cover-description {
    max-width: 450px;
    font-size: 16px;
    font-weight: 400;
}

.cover .ant-btn {
    margin-top: 30px;
    min-width: 160px;
    font-size: 15px;
    min-height: 50px;
    font-family: 'Roboto', sans-serif !important;
}

.cover .globe-wrapper {
    text-align: center;
    padding: 15px;
    position: relative;
}


.cover .globe-wrapper img {
    margin: auto;
    width: 90%;
    max-width: 500px;
    position: relative;
}

.cover .globe-wrapper::before {
    content: '';
    position: absolute;
    top: -15%;
    left: -25%;
    height: 140%;
    width: 140%;
    /* background-color: red; */
    background: url('../../../images/blurred-bg.png');
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.cover .divider {
    width: 113px;
    height: 7px;
    background: #FFFFFF;
    margin-bottom: 30px;
}

#tsparticles {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

/* mobile styles upto 768px */
@media only screen and (max-width: 768px){
   .cover .divider{
        display: none;
    }

    .cover .left-col{
        text-align: center;
    }

    .cover .row .ant-row{
        align-content: center;
    }

    .cover::before {
        display: none;
    }

    .cover .globe-wrapper::before{
        top: 0;
        left: -22%;
        overflow: hidden;
        width: 125%;
    }
}