.audit-card {
    background: #141f4096;
    border-radius: 10px;
    border: 0.5px solid #4648D8;
    min-width: 265px;
    max-width: 265px;
    transition: all 0.25s ease-in-out;
    position: relative;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.audit-card:hover {
    box-shadow: 0px 0px 15px 2px #30317C;
    cursor: pointer;
}

.audit-card-bordered {
    border-color: #4648D8;
}

.audit-card a {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 15%;
    cursor: pointer;
}

.audit-card .audit-card-content {
    /* min-height: 250px; */
    display: flex;
    flex-direction: column;
    justify-content: top;
    /* padding: 25px; */
}

.audit-card .audit-card-content .card-image {
    max-width: 75px;
    /* margin-bottom: 25px; */
}

.audit-card .audit-card-content .audit-title {
    font-weight: 600;
    font-size: 22px;
    /* line-height: 125.5%; */
}

.audit-card .audit-card-footer {
    background: #1C213D;
    padding: 15px 25px;
    border-radius: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.audit-card .audit-card-footer .pill-item {
    background: #4648D8;
    border-radius: 22px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    padding: 4px 15px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.audit-card .audit-card-footer .pill-item:nth-child(1) {
    margin-left: 0;
}

.audit-card .audit-card-footer .pill-item:hover {
    background-color: #474aff;
    box-shadow: 0px 0px 12px 1px #474aff;
}

.button-alignment {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.audit-vote-button {
    border: 1px solid #3BB900;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    height: 40px;
    font-weight: 500;
    transition: all 0.25s ease-in-out;
}

.audit-vote-button:hover {
    /* background: #3BB900; */
    border: 1px solid #3BB900;
    box-shadow: 0px 0px 15px 2px #3BB900;
}

.audit-vote-button::after {
    background: #3BB900;
    border: 1px solid #3BB900;
}

.audit-vote-button:focus,
.audit-vote-button:hover {
    color: #ffffff;
    border-color: #3BB900;
}

.featured-audit-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 4px;
}

.audit-sub-text {
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
}

.audit-sub-text2 {
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 100;
    justify-content: space-between;
}

.Audited-vote-fire {
    margin-top: -4px;
}