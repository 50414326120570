.vote-button {
    /* background: #3BB900;
    border-radius: 5px;
    cursor: pointer;
    width: 80px;
    height: 30px;
    font-weight: 500; */
    border: 1px solid #3BB900;
    background: #3bb9002b;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    height: 38px;
    font-weight: 500;
    transition: all 0.25s ease-in-out;
}

.vote-button:hover {
    /* background: #3BB900; */
    color: #ffffff;
    border: 1px solid #3BB900;
    box-shadow: 0px 0px 15px 2px #3BB900;
}

.vote-button::after {
    background: #3BB900;
    border: 1px solid #3BB900;
}

.vote-button-voted {
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    height: 38px;
    font-weight: 500;
    background: #3BB900;
    border: 1px solid #3BB900;
}

.vote-button-voted:hover {
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    height: 38px;
    font-weight: 500;
    background: #3BB900;
    border: 1px solid #3BB900;
    color: #ffffff;
}

.vote-button-voted:after {
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    height: 38px;
    font-weight: 500;
    background: #3BB900;
    border: 1px solid #3BB900;
    color: #ffffff;
}

.vote-button-voted:focus {
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    height: 38px;
    font-weight: 500;
    background: #3BB900;
    border: 1px solid #3BB900;
    color: #ffffff;
}

.vote-button:focus {
    color: #ffffff;
    border-color: #3BB900;
    background: #3BB900;
}

.vote-now-button:active {
    background: #3BB900;
}

.fire-icon {
    margin-top: -4px;
}