.steps-list-button {
    margin-top: 30px;
    margin-left: 100px;
}

.tag-margin {
    margin-left: 20px;
    border: 3px solid #4648d8;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 0 !important;
}