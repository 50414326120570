.safu-tag {
    background: linear-gradient(135deg, #cbc62b 0%, #56c729 100%);
    height: 22px;
    width: 45px;
}

.law-tag {
    background: #33C2FF;
    height: 22px;
    width: 45px;
}

.medium-tag {
    background: #10239e;
    height: 22px;
    width: 45px;
}