.tech-stack-section{
    background-color: #000930;
    background-size: 60%;
    background-position: left center;
    background-repeat: no-repeat;
}

.tech-stack-section h2{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.tech-stack-section .tech-row{
    margin-bottom: 20px;
    flex-wrap: nowrap;
    gap: 15px;
}

.tech-stack-section .tech-row:last-child{
    margin-bottom: 50px;
}

.tech-stack-section .tech-row .ant-col{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #30317C;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

.tech-stack-section .tech-row .ant-col:hover{
    transform: scale(1.01);
}

.tech-stack-section .tech-row .ant-col .img-wrapper{
    padding: 15px;
}

.tech-stack-section .tech-row .ant-col .img-wrapper img{
    border-radius: 50%;
}

.tech-stack-section .tech-row .ant-col h4{
    margin-bottom: 0;
}

@media only screen and (max-width: 768px){
    .tech-stack-section .tech-row{
        flex-direction: column;
    }

    .tech-stack-section .tech-row .text-wrapper{
        padding-right: 20px;
    }

    .tech-stack-section h2{
        width: 100%;
    }
}
