.Audit-tabs-card {
    background: #0A0F1D;
    /* border: 1px solid #30317C; */
    color: #ffffff;
    /* border: 2px solid #4648D8; */
    border-radius: 10px;
    transition: all 0.25s ease-in-out;
    position: relative;
}

.Audit-tabs-section {
    background: #0c0e26b5;
}

.tabs-pain-align {
    margin-left: 50px;
    padding-left: 56px;
}