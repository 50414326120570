.logo-slider-section{
    padding-top: 80px;
    padding-bottom: 80px;
    text-align: center;
}

.swiper-wrapper{
    z-index: 0;
}

.swiper-slide{
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-slider-section .ant-col{
    text-align: center;
}

.logo-slider-section .ant-col .description {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 168%;
}