.datepicker-size {
    padding: 6.5px;
}

.connect-metamask {
    border: 1px solid #3230b3;
    color: #ffffffd9;
}

.connect-metamask:hover {
    background: #4648d8;
    color: #ffffffd9;
}

.connect-metamask:focus {
    /* border: 1px solid #2435b4;  */
    color: #fff;
    border-color: #3230b3;
    background: #3230b3;
}