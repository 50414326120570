.token-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.token-list-item img {
    margin-right: 15px;
}

.token-list-item .token-name {
    margin-right: 15px;
    color: #d8d8d8; 
}

.token-list-item .token-symbol {
    opacity: 0.4;
    text-transform: uppercase;
    font-size: 12px;
}

.info.percentage {
    color: #2a9e2a;
    font-size: 14px;
}

.info.moment_date {
    color: #797979;
}

.token-brief-card-row{
    margin-bottom: 20px;
}

.filter-drop-down {
    width: 170px;
}

.ant-select-selector{
    width: 100%;
}

.token-list-item{
    padding: 5px 10px ;
}

.token-list-item:hover{
    background-color: #1c213d;
    
}

.token-list-item:hover .token-name{
    color: #fff;
}

.token-list-item:hover .moment_date{
    color: #b5b5b5;
}

.token-list-item:hover .percentage{
    color: #58ea58;
}

.token-list-item .token-name,
.token-list-item .moment_date,
.token-list-item .percentage{
    transition: all 0.35s ease-in-out;
}

@media (max-width: 768px) {

    .token-info-card .ant-card-body,
    .token-info-card .ant-card-head {
        padding: 14px !important;
    }


    .token-info-card .ant-card-head .ant-card-head-title {
        padding: 5px 0;
    }

    .token-info-card {
        margin-bottom: 15px;
    }

    .token-brief-card-row{
        margin-bottom: 0px;
    }

    .filter-dropdown-area{
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 15px;
        row-gap: 20px;
    }
    
    .filter-drop-down{
        width: 100%;
    }
    
    .dropfilter-item{
        width: calc(50% - 10px);
    }
}