.logo-showcase {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.logo-showcase h2 {
    margin-bottom: 50px;
}

.logo-showcase .logo-row .ant-col {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 60px 60px;
    justify-content: center;
}

@media (max-width: 768px) {
    .logo-showcase .logo-row .ant-col img {
        max-width: 120px;
    }
}