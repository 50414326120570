.audit-button {
    border: 1px solid #ffffff;
    background: #4648D8;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.audit-button-text {
    /* padding-top: 3px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.audit-button-text {
    /* padding-top: 3px; */
}

.download-Icon {
    /* padding-bottom: 10px; */
    display: flex;
    /* padding-bottom: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 7px; */
    position: sticky;
    align-items: center;
    /* padding: 7px; */
    position: sticky;
}

/* .vote-button {

    border: 1px solid #3BB900;
    background: #3bb9002b;
    border-radius: 5px;
    cursor: pointer;
    width: 110px;
    height: 38px;
    font-weight: 500;
    transition: all 0.25s ease-in-out;
}

.vote-button:hover {
    color: #ffffff;
    border: 1px solid #3BB900;
    box-shadow: 0px 0px 15px 2px #3BB900;
}

.vote-button::after {
    background: #3BB900;
    border: 1px solid #3BB900;
}

.vote-button:focus {
    color: #ffffff;
    border-color: #3BB900;
    background: #3BB900;
}

.vote-now-button:active {
    background: #3BB900;
} */

.marketcap-text {
    font-weight: 800;
    /* font-size: 17px; */
}

.ant-select-item-option-content img,
.ant-select-selection-item img{
    width: 20px;
    margin-right: 5px;
}

.filter-dropdown-area{
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    padding-left: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.filter-dropdown-area .dropfilter-item{
    font-size: 26px;
}

.filter-dropdown-area .dropdown-item-name{
    font-size: 10px;
    padding-left: 5px;
    font-weight: 300;
    color: #8b8de5;
    margin-bottom: -5px;
}