.stats-section {
    background-color: #0A133A;
    min-height: 290px;
    z-index: 2;
}


.stats-sections {
    background-color: #0A133A;
    min-height: 100px;
    z-index: 2;
}

.stats-sections-red {
    background-color: rgba(255, 25, 67, 0.1);
    min-height: 2px;
    z-index: 2;
}

.red-note-text {
    color: #FF395C;
}

.orange-note-text {
    color: #c6b218;
}

.stats-sections-orange {
    background-color: rgba(230, 196, 44, 0.1);
    min-height: 2px;
    z-index: 2;
    max-width: fit-content;
    margin-left: 12.5%;
    border-radius: 15px;
}

.stats-section .ant-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.stats-section .ant-col .description {
    max-width: 450px;
}

.stats-section .ant-col.ant-col-md-14 .stats-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.stats-section .ant-col.ant-col-md-14 .stats-wrapper .stat-item {
    width: 33.33%;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.stats-section .ant-col.ant-col-md-14 .stats-wrapper .stat-item:nth-child(2n) {
    border: none;
    border-left: 2px dashed #243b556c;
    border-right: 2px dashed #243b556c;
}

.revealing-animate {
    position: relative;
    overflow: hidden;
    display: block;
    width: 75%;
}

.stat-item .stat-no {
    font-size: 40px;
    font-weight: 400;
}

.stat-item .stat-no:nth-of-type(1)::before,
.stat-item .stat-no:nth-of-type(1)::after {
    animation-delay: 1s;
}

.revealing-animate::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    animation: a-ltr-before 1s cubic-bezier(.77, 0, .18, 1) forwards;
    transform: translateX(0);
}

.revealing-animate::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    background: linear-gradient(135deg, #43CBFF 0%, #9708CC 100%);
    animation: a-ltr-after 1s cubic-bezier(.77, 0, .18, 1) forwards;
    transform: translateX(-101%);
}

.stats-section .ant-col.ant-col-md-14 .stats-wrapper .stat-item .stat-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
}

/* reavaling animation for AOS */
[aos="revealing-animate"] {
    position: relative;
    overflow: hidden;
    display: block;
    max-width: 120px;
    margin-left: auto;
    margin-right: auto;
    transition-property: all;
}

[aos="revealing-animate"].aos-animate {}

@keyframes a-ltr-after {
    0% {
        transform: translateX(-100%)
    }

    100% {
        transform: translateX(101%)
    }
}

@keyframes a-ltr-before {
    0% {
        transform: translateX(0)
    }

    100% {
        transform: translateX(200%)
    }
}

/* mobile styles upto 768px */
@media only screen and (max-width: 768px) {

    .revealing-animate {
        width: 100%;
    }

    .stats-section {
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
    }

    .stats-wrapper {
        flex-wrap: wrap !important;
    }

    .stats-wrapper .stat-item {
        width: 50% !important;
    }

    .stats-section .stats-wrapper {
        margin-top: 30px;
    }

    .stats-section .stats-wrapper .stat-item .stat-no {
        font-size: 40px;
    }

    .stats-section .stats-wrapper .stat-item .stat-title {
        line-height: 1.5em !important;
    }

    .stats-section .ant-col.ant-col-md-14 .stats-wrapper .stat-item:nth-child(2n) {
        border: none;
    }
}