.mobile-nav-icon {
    margin-top: -3px;
    margin-right: 5px;
}

.mobile-nav-link {
    font-weight: 600;
}

.ant-drawer-body {
    background-color: #4648D8;
    /* background: linear-gradient(180deg, rgba(46,47,133,1) 0%, rgba(0,0,0,1) 100%); */
    background-image: url(../../images/audit-card-bg.svg);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
}

.close-icon {
    padding-left: 210px;
    margin-top: -25px;
}
