.watchlist-icon-margin {
    margin-right: -10px;
}


.watchlist-star-icon-margin {
    margin-right: -25px;
    margin-top: -3px;
}

@media screen and (min-width: 480px) {
    .watchlist-star-icon-margin {
        margin-right: -25px;
        margin-top: -3px;
    }
}