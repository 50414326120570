/* stats header related data - places just under navbar */
.scollable-stat-area {
    padding: 5px 0px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.stats-area-bg {
    background-color: #000000;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
}

.stats-highlighted-text {
    color: #fa541c;
}