.coin-information-card {
    /* max-width: 370px; */
    min-width: 100%;
    min-height: 500px;
    /* margin-left: 15px; */
    margin-right: 20px;
    /* margin-bottom: 10px; */
    background: #0A0F1D;
    border: 1px solid #30317C;
    /* border: 2px solid #4648D8; */
    border-radius: 10px;
    transition: all 0.25s ease-in-out;
    position: relative;
}

.coin-information-card-title {
    font-weight: 900;
    font-size: 22px;
}

.coin-information-logo {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.coin-information-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 0.5px solid #3F3D56;
}

.ci-text {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 400;
    justify-content: space-between;
}

.ci-text2 {
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 600;
    justify-content: space-between;
}

.ci-audit-tag-green {
    color: #34A853;
    border: 1px solid #34A853;
    margin: 0 0 7px 0;
}

.ci-audit-tag-green:hover,
.ci-audit-tag-green:focus {
    color: #34A853;
    border: 1px solid #34A853;
}

.green-eye-icon {
    margin-right: -10px;
}

.ci-kyc-tag-blue {
    color: #229ED9;
    border: 1px solid #229ED9;
    margin: 0 0 7px 0;
}

.ci-kyc-tag-blue:hover,
.ci-kyc-tag-blue:focus {
    color: #229ED9;
    border: 1px solid #229ED9;
}

.blue-eye-icon {
    margin-right: -10px;
}

.ci-status-tag {
    color: #3BB900;
    border: 0;
    background: rgba(68, 214, 0, 0.1);
    margin: 0 0 7px 0;
}

.ci-presale-status-tag {
    color: #FF395C;
    border: 0;
    background: rgba(255, 25, 67, 0.1);
    margin: 0 0 7px 0;
}

.ci-listing-tag {
    color: #0AB6FF;
    border: 0;
    background: #0ab5ff1d;
    margin: 0 0 7px 0;
}

.ci-votes-tag {
    border: 0;
    background: 0;
    margin: 0 0 7px 0;
}

.ci-fire-icon {
    margin-top: -6px;
    margin-right: 5px;
    height: 14px;
    width: 11px;
}

@media(max-width: 680px){
    .coin-information-card .ant-card-body{
        padding: 20px !important;
    }
}