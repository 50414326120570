.finished-button {
    border: 1px solid #3BB900;
    background: #3bb9002b;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    height: 38px;
    font-weight: 500;
    transition: all 0.25s ease-in-out;
}

.finished-button:hover {
    color: #ffffff;
    border: 1px solid #3BB900;
    box-shadow: 0px 0px 15px 2px #3BB900;
}

.finished-button::after {
    background: #3BB900;
    border: 1px solid #3BB900;
}

.finished-button:focus {
    background: #3BB900;
    border: 1px solid #3BB900;
    color: #ffffff;
}