.audits-section-inner .heading-2{
    text-align: center;
}

.audits-section-inner .description{
    max-width: 750px;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    font-size: 16px;
    line-height: 160%;
}