.home-tabs-card {
    background: #0A0F1D;
    border: 4px solid #30317C;
    color: #ffffff;
    border-radius: 0px !important;
    transition: all 0.25s ease-in-out;
    position: relative;
    -webkit-box-shadow: 0px 3px 48px -16px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 48px -16px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 48px -16px rgba(0,0,0,0.75);
}

.home-tabs-section {
    background: #0c0e26b5;
}