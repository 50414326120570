.home-tabs-card.not-found-page h1{
    font-size: 200px;
    z-index: 3;
}

.home-tabs-card.not-found-page .ant-card-body{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.home-tabs-card.not-found-page{
    background: url('../../images/404-bg.svg');
    background-size: 20%;
    background-repeat: repeat;
}

.home-tabs-card.not-found-page .ant-card-body::before{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: '';
background: rgb(19,20,78);
background: radial-gradient(circle, rgba(19,20,78,0.4) 58%, rgba(19,20,78,1) 100%);
    z-index: 0;
}

.home-tabs-card.not-found-page .ant-card-body{
    position: relative;
    z-index: 5;
}

.home-tabs-card.not-found-page .ant-card-body > div{
    z-index: 99;
}

.home-tabs-card.not-found-page .ant-card-body div > p{
    font-size: 22px;
    max-width: 550px;
}