.red-span {
  color: #bb0115;
}

.green-span {
  color: #22ba46;
}

.card-image {
  height: 100%;
  object-fit: cover;
}

.detail-card-image .ant-card-body {
  display: none !important;
}

.detail-card-image {
  padding: 5px;
  border: 2px solid #30317C;
  border-radius: 0px !important;
}

.letter-blue {
  color: #007aff;
}

.coin-details-widget-topic {
  font-size: 12px;
}

.coin-details-widget-value {
  font-size: 18px;
}

.detail-card-widget {
  padding-right: 70px;
  border: 2px solid #30317C;
  border-radius: 0px !important;
}

@media screen and (max-width:1000px) {
  .detail-cards {
    display: block !important;
    padding: 10px;
  }

  .brc-20-search {
    width: 100% !important;
  }

  .mobile-margin-top {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mobile-side-margin {
    margin-inline: 0px !important;
  }
}

.green-text {
  color: #4caf50;
}

.chart-price-values {
  /* margin-top: 4px; */
  color: #6e6d6d;
  font-size: 11px;
}

.listed-table .ant-table-tbody {
  font-size: 12px !important;
}

.brc-20-search {
  width: 50%;
}

.mobile-side-margin {
  margin-inline: 10px;
}

/* brc20 inner pages fix - mobile */
@media(max-width: 680px) {

  /*   brc20 inner page table price column fix  */
  .brc20-list-section .listed-table .ant-table-row:nth-child(odd) .price-col {
    background: #0a0f1d;
    z-index: 99;
  }

  .brc20-list-section .listed-table .ant-table-row:nth-child(even) .price-col {
    background: #020105;
    z-index: 99;
  }

  /*   brc20 inner page table total value column fix  */
  .brc20-list-section .listed-table .ant-table-row .total-val-col {
    z-index: 99;
    left: 19% !important;
  }

  .brc20-list-section .listed-table .ant-table-row:nth-child(odd) .total-val-col {
    background: #0a0f1d;
  }

  .brc20-list-section .listed-table .ant-table-row:nth-child(even) .total-val-col {
    background: #020105;
  }

  /*   brc20 inner page table total value column header fix  */
  .brc20-list-section .listed-table tr:nth-child(odd) th.total-val-col {
    left: 19% !important;
  }

  /* brc20 search box mobile fixes */
  .brc-20-search{
    margin-bottom: 15px;
  }
}
.ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link {
  font-size: 15px !important;
  margin-top: 0px !important;
}