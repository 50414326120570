.service-card {
    /* max-width: 25%; */
    max-width: 250px;
    min-width: 250px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    /* margin: 5px; */
    /* margin-right: 10px;
   margin-top: 5px; */
    background: #0A0F1D;
    border: 0.5px solid #4648D8;
    border-radius: 10px;
}

.service-card .ant-btn-link {
    padding: 0;
}

.service-card .service-card-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 125.5%;
}

.service-card .service-card-description{
    margin-bottom: 40px;
}

.service-card .card-icon{
    margin-bottom: 45px;
}