.bg-blur-nav {
    background-color: #000000;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 1 !important;
    /* this line added to show side menu drawer fully */
}

.navbarlink {
    color: #ffffff;
    margin-right: 15px;
    font-weight: 400 !important;
    font-size: 14px;
    padding: 5px 0px;
    /* width: 0;
    border-bottom: 2px solid #fff !important;
    transition: .7s; */
}

.navbarlink:hover {
    color: #4648D8;
    padding: 5px 0px;
    font-weight: 400 !important;
    font-size: 14px;
    transition: 0.2s;
    width: 90%;
    border-bottom: 2px solid #4648D8 !important;
    transition: .2s;
}

.list-navbarlink {
    margin-right: 20px;
}

.list-navbarlink:hover {
    color: #4648D8;
    /* padding: 5px 0px;
    font-weight: 400 !important;
    font-size: 16px;
    transition: 0.2s;
    width: 90%; */
    /* border-bottom: 2px solid #4648D8 !important; */
    /* transition: .2s; */
}

.centered-navbar ul {
    margin-bottom: 0;
}

.navbar-brand div h4 {
    margin-bottom: 0;
}

.logo-text {
    margin-left: 4px;
}

@media only screen and (max-width: 768px) {
    .hide-on-mobile {
        display: none !important;
    }

    .navbar-brand {
        align-items: center;
    }

    .navbar-brand div h4 {
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 769px) {
    .hide-on-pc {
        display: none !important;
    }
}

/* navbar drop down */
.tools-dropdown-menu{
    position: absolute;
    right: -65px;
    top: 20px;
    background: black;
    padding: 20px 30px;
    padding-bottom: 10px;
    list-style-type: none;
    display: none;
    border-radius: 5px;
    -webkit-animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.tools-dropdown-menu li{
    margin-bottom: 10px;
}

.tools-menu-item{
    cursor: pointer;
    position: relative;
}

.tools-menu-item::after{
    position: absolute;
    right: -15px;
    top: 10px;
    height: 5px;
    width: 5px;
    background: transparent;
    content: '';
    border: 5px solid transparent;
    border-top: 5px solid #fff;
    
}

.tools-menu-item:hover .tools-dropdown-menu{
   display: block;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-18 13:13:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  