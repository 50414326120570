.promoted-token-container {
    border: 1px solid #30317C;
    border-radius: 10px;
    background: #0A0F1D;
    min-height: 570px;
    max-height: 570px;
}

.marketcap-text {
    font-weight: 800;
    /* font-size: 17px; */
}