@import './Styles/rfc-theme.css';

* {
    font-family: 'Roboto', sans-serif !important;
}

body {
    overflow-x: hidden !important;
}

/* center a div */
.center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.lines {
    position: absolute;
    top: 22px;
    display: block;
    width: 0;
    border-bottom: 2px solid #fff !important;
    transition: .7s;
}

.lines:hover {
    position: absolute;
    top: 22px;
    display: block;
    width: 90%;
    border-bottom: 2px solid #fff !important;
    transition: .7s;
}

/* token logo related styles */
.token-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #141414;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    object-fit: cover;
    overflow: hidden;
}

.star-tag {
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    background: #222D35;
}

.heading-1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 125.5%;
    color: #FFFFFF;
}

.featured-audits-card {
    background: url('./images/audit-card-bg.svg') !important;
    background-size: cover !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
    border: 4px solid #30317C !important;
    color: #ffffff;
    border-radius: 0px !important;
    transition: all 0.25s ease-in-out;
    position: relative;
    -webkit-box-shadow: 0px 3px 48px -16px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 48px -16px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 48px -16px rgba(0, 0, 0, 0.75);
}

.featured-audited-token {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #0A0F1D;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    object-fit: cover;
    overflow: hidden;
}

@media only screen and (max-width: 365px) {
    .featured-audited-token {
      margin-top: -30px !important;
    }
  }

h2,
.heading-2 {
    font-size: 40px;
    font-weight: 500;
    line-height: 125.5%;
}

body::-webkit-scrollbar {
    width: 12px !important;
    /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
    background: #0A0F1D !important;
    /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color: #30317C !important;
    /* color of the scroll thumb */
    border-radius: 10px !important;
    /* roundness of the scroll thumb */
    border: 3px solid #0A0F1D !important;
    /* creates padding around scroll thumb */
}

table::-webkit-scrollbar {
    width: 12px !important;
    /* width of the entire scrollbar */
}

table::-webkit-scrollbar-track {
    background: #0A0F1D !important;
    /* color of the tracking area */
}

table::-webkit-scrollbar-thumb {
    background-color: #30317C !important;
    /* color of the scroll thumb */
    border-radius: 10px !important;
    /* roundness of the scroll thumb */
    border: 3px solid #0A0F1D !important;
    /* creates padding around scroll thumb */
}

/* ant table customizations */

.ant-table,
.ant-table-header {
    background-color: #0A0F1D !important;
}

.ant-table-thead>tr>th {
    background-color: #1C213D !important;
}

.ant-table-scrollbar {
    width: 12px !important;
    /* width of the entire scrollbar */
}

.ant-table-scrollbar-thumb {
    background: #0A0F1D !important;
    /* color of the tracking area */
}

.ant-table-scrollbar-thumb:window-inactive {
    background-color: #30317C !important;
    /* color of the scroll thumb */
    border-radius: 10px !important;
    /* roundness of the scroll thumb */
    /* border: 3px solid #0A0F1D !important; */
    /* creates padding around scroll thumb */
}

.ant-table-tbody>tr>td {
    border-top: none !important;
    border-bottom: none !important;
}

.table-row-light {
    background-color: transparent !important;
}

.table-row-dark {
    background-color: #020105 !important;
}

.site-layout {
    background: rgb(46, 47, 133);
    background: radial-gradient(circle, rgba(46, 47, 133, 1) 0%, rgba(0, 0, 0, 0.3) 100%);
}

.ant-tabs-tab {
    border: 4px outset #30317C !important;
    border-bottom: none !important;

}

.ant-tabs-tab-active {
    background: #1c213d !important;
    border-bottom: none !important;
}

.div.ant-tabs-tab-ant-tabs-active {
    /* border: 10px solid #020105 !important; */
}

.ant-table-tbody .ant-table-cell-fix-left:nth-child(even) {
    background-color: transparent;
}

.ant-input-affix-wrapper {
    border: none !important;
    background: #000 !important;
}

.ant-picker {
    border: none !important;
    background: #000 !important;
}

.ant-form-item-control-input textarea {
    border: none !important;
    background: #000 !important;
}

.ant-form-item-control-input input {
    border: none !important;
    background: #000 !important;
}

.ant-select-selector {
    border: none !important;
    background: #000 !important;
}

.iframe-styles {
    width: 970px;
    height: 90px;
    border: 0px;
    padding: 0;
    overflow: hidden;
    background-color: transparent;
}

.ant-input-affix-wrapper {
    border: 1px solid #30317C !important;
}

/* mobiles styles upto 786px */
@media only screen and (max-width: 768px) {
    .heading-1 {
        font-size: 40px;
    }

    h2,
    .heading-2 {
        font-size: 35px;
    }

    .ant-table-tbody .ant-table-cell-fix-left:nth-child(even) {
        background-color: #0A0F1D !important;
    }

    .iframe-styles {
        width: 350px;
        height: 90px;
        border: 0px;
        padding: 0;
        overflow: hidden;
        background-color: transparent;
    }

    /* center a div */
    .center {
        position: relative;
        left: auto;
        top: auto;
        transform: none;
    }
}