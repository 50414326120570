.trusted-coin-card {
    /* max-width: 370px; */
    min-width: 260px;
    min-height: 570px;
    max-height: 570px;
    /* margin-left: 15px; */
    margin-right: 10px;
    /* margin-bottom: 10px; */
    background: #0A0F1D;
    border: 1px solid #30317C;
    /* border: 2px solid #4648D8; */
    border-radius: 10px;
    transition: all 0.25s ease-in-out;
    position: relative;
}

.trusted-coin-card .ant-btn-link {
    padding: 0;
}

.trusted-coin-card .trusted-coin-card-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 125.5%;
}

.card-image-mt {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px;
}

.card-icon-tc {
    height: 80px;
    width: 80px;
    /* margin-top: 5px; */
    padding: 8px;
    margin-right: 4px;
    /* border: 1px solid #dac9c9; */
    border-radius: 50%;
    background: rgb(0, 0, 0);
}

.subtext {
    font-weight: 400;
    font-size: small;
    margin-top: 15px;
}

.subtext1 {
    margin-top: -10px;
}

.fireIcon {
    margin-right: 4px;
    margin-top: -5px;
}

.download-icon {
    margin-left: -8px;
    margin-top: -10px;
    /* padding: 3px; */
}

.sub-headers {
    font-size: small;
    font-weight: 100;
}

.risk-level-button {
    background: linear-gradient(135deg, #cbc62b 0%, #56c729 100%);
    border-radius: 5px;
    /* margin: 1px; */
    color: #0A0F1D;
    font-weight: 500;
    height: 22px;
    width: 45px;
    /* margin-left: -2px; */
}

.risk-level-button:hover {
    background: linear-gradient(135deg, #FFF720 0%, #3CD500 100%);
    border-radius: 5px;
    height: 22px;
    width: 45px;
    /* border: 1px solid linear-gradient(135deg, #f8f133 0%, #46eb05 100%); */
    /* margin: 1px; */
}

.thumbIcon {
    padding-left: 5px;
    margin-top: -6px;
}

.button-text-color {
    color: #ffffff;
    margin-left: -3px;
}

.line-separator {
    border-left: 1px solid rgba(255, 255, 255, 0.185);
    height: 110px;
    margin-top: 5px;
}

.vote-now-button {
    border: 1px solid #3BB900;
    background: #3bb9002b;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    height: 43px;
    font-weight: 500;
    transition: all 0.25s ease-in-out;
}

.vote-now-button:hover {
    color: #ffffff;
    border-color: #3BB900;
    /* background: #3BB900; */
    box-shadow: 0px 0px 15px 2px #3BB900;
}

.vote-now-button:focus {
    color: #ffffff;
    border-color: #3BB900;
    background: #3BB900;
}

.vote-now-button:active {
    background: #3BB900;
}

.vote-fire {
    margin-top: -4px;
}

/* .vote-fire:hover {
    border: 1px solid #3BB900;
} */