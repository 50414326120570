.event-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.event-info .event-logo {
    width: 32px;
    border-radius: 100px;
    margin-right: 10px;
}


.events-table .ant-table-cell {
    text-align: center;
}

.events-table .ant-table-cell.event-name-cell {
    text-align: left;
}

.events-table .ant-table-row:nth-child(even) {
    background-color: #020105 !important;
}

.events-table .ant-tag {
    text-transform: uppercase;
}

.events-table .event-website {
    text-decoration: underline;
}

.events-table .event-website:hover {
    color: #6B6DE0 !important;
}

.events-table .ant-table-cell a {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.events-table .ant-table-cell.event-website-table-cell a {
    position: relative;
}

.upcoming-events-tab table .past-event {
    display: none;
}

.past-events-tab table .upcoming-event {
    display: none;
}

.ant-pagination-item-link span{
    transform: translatey(-4px);
}

/* event tabs responsive */
@media(max-width: 645px) {
    .ant-tabs-nav {
        flex-direction: column-reverse;
        transform: translate(0, 0) !important
    }

    .ant-tabs-nav .ant-tabs-extra-content,
    .ant-tabs-nav .ant-tabs-extra-content>div {
        width: 100%;
        margin-bottom: 10px;
    }

    .ant-tabs-nav .ant-tabs-nav-list {
        transform: translate(0, 0) !important;
    }

    .events-card .ant-tabs-nav-list {
        width: 100%
    }

    .events-card .ant-tabs-nav-list .ant-tabs-tab {
        width: 50%;
    }

    .events-card .ant-tabs-nav-operations {
        display: none !important;
    }

    .events-card .ant-tabs {
        min-width: 200px;
    }

    .events-card .ant-tabs-nav .ant-tabs-tab {
        padding: 8px 5px;
        flex-direction: row;
        justify-content: center;
    }

    .events-card .ant-tabs-tab .anticon {
        margin-right: 5px;
    }

    .events-card .event-name-cell {
        position: sticky;
        left: 0;
        z-index: 2;
    }

    .events-card .ant-table-row:nth-child(odd) .event-name-cell {
        background: #0a0f1d;
    }

    .events-card .ant-table-row:nth-child(even) .event-name-cell {
        background: #020105;
    }

    .events-card .ant-card-body {
        padding: 10px;
    }

}